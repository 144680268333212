.botSectionSetup {
    display: flex;
    flex-direction: column;
    flex: 1;
    &__messages {
        margin: 0;
    }
    &__buttons {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 5px;
        width: 100%;
    }
    .channelsSelector__button {
        margin: 12px 0;
    }
    .buttonsBar {
        display: flex;
        justify-content: space-between;
    }
}
