@numberOfCountsItems: 12;
.newsItemLoader {
    background-color: #f2f6f9;
    border-radius: 8px;
    height: 310px;
    margin-left: 10px;
    margin-right: 10px;
    min-width: 270px;
    overflow: hidden;
    padding-bottom: 162px;
    position: relative;
    .colorPulse();
    .appendAnimationDelay(@numberOfCountsItems);
    &__content {
        background-color: #e4e9ef;
        bottom: 0;
        height: 166px;
        position: absolute;
        width: 100%;
        // Platform icon background
        &:before {
            background-image: url('assets/IconBubble.svg');
            content: '';
            display: block;
            height: 30px;
            left: 0;
            position: absolute;
            top: -30px;
            width: 129px;
        }
        // Platform icon
        &:after {
            background-color: #f2f6f9;
            border-radius: 50%;
            content: '';
            display: block;
            height: 32px;
            left: 23px;
            position: absolute;
            top: -20px;
            width: 32px;
            .colorPulse();
            .appendAnimationDelay(@numberOfCountsItems);
        }
        &Labels {
            width: 210px;
            height: 112px;
            border-bottom: 1px solid #f2f6f9;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        &Label {
            background-color: #f2f6f9;
            border-radius: 3px;
            display: block;
            height: 20px;
            width: 100%;
            .colorPulse();
            .appendAnimationDelay(@numberOfCountsItems);
            &:nth-child(1) {
                margin-bottom: 5px;
            }
            &:nth-child(2) {
                width: 80%;
            }
        }
        &Button {
            background: #f2f6f9;
            border-radius: 3px;
            display: block;
            height: 21px;
            margin: 13px auto 16px;
            width: 150px;
            .colorPulse();
            .appendAnimationDelay(@numberOfCountsItems);
        }
    }
    @keyframes colorPulse {
        0% {
            background-color: #f2f6f9;
        }
        50% {
            background-color: #f8fafc;
        }
        100% {
            background-color: #f2f6f9;
        }
    }
    .colorPulse() {
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-name: colorPulse;
    }
    .appendAnimationDelay(@counter) when (@counter > 0) {
        .appendAnimationDelay((@counter - 1));
        &:nth-child(@{counter}) {
            animation-delay: ~'.@{counter}s';
        }
    }
}
