@import '../../../../../../media-to-refactor/bootstrap/variables.less';
@import '../../../../../../media-to-refactor/bootstrap/mixins/clearfix.less';

/* Limit Element */

.form-control.with-error {
    border-color: @red-500;
}

.form-control-error {
    font-size: @font-size-UI12;
    color: @red-500;
}
.partials-char-indicator {
    clear: both;
    position: relative;

    .charactersIndicator {
        bottom: -8px;
        right: -5px;
        display: none;
    }
    #partialstext:focus + & {
        .charactersIndicator {
            display: block;
        }
    }
}
