@import '../../../../shared/components/design';

.onIE(@rules) {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @rules();
    }
}

.contacts {
    .side {
        display: flex;
        flex-direction: column;
    }
    .data {
        background-color: @white;
        border-radius: 12px;
        border: 1px solid @gray-150;
    }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .contacts .media {
        overflow: auto;
    }
}
