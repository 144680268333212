@import '../../../../shared/components/design';
.discount-landing {
    background-color: #0d1b5d;
    color: @white;
    text-align: center;
    font-size: 34px;
    line-height: 1.35;
    font-weight: 600;
    font-family: euclidcircularb, sans-serif;
    padding: 35px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
        width: 125px;
        .st0 {
            fill: @white;
        }
    }
    .logo,
    .text {
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .text {
        max-width: 20em;
    }

    .laptop {
        width: 100%;
        max-width: 375px;
        height: 444px;
        background: url(./assets/laptop.png) no-repeat;
        background-size: contain;
    }
}
.mailing-landing {
    background-color: @white;
    color: @black;
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: euclidcircularb, sans-serif;
    .logo {
        margin-bottom: 24px;
        svg {
            width: 125px;
        }
    }
    .image {
        position: relative;
        width: 320px;
        height: 200px;
        margin-top: 24px;
        margin-bottom: 32px;
    }
    .text {
        font-size: 38px;
        font-weight: 600;
        letter-spacing: -1.9px;
        margin-bottom: 20px;
        line-height: 48px;
    }
    .text-small {
        font-size: @font-size-UI18;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: -0.24px;
    }
}
