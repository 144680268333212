@import '../../../media-to-refactor/bootstrap/mixins/buttons';
@import '../../../shared/components/design';

.mailing-creator-wrapper,
.mailing-new-creator-wrapper {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    .btn.btn-outline.btn-loading {
        &:before {
            width: 20px;
            height: 20px;
            background-image: url('../../../public/media/img/panel/loading-gray.png');
            top: calc(50% - 10px);
            left: calc(50% - 10px);
        }
    }
    .box {
        border-radius: 4px;
        box-shadow: @shadow-elevation-6-12;
        &--disabled {
            border: solid 1px @athens-gray;
            background-color: #f7f8fc;
            .box-header {
                color: @gray-500;
            }
        }
        &-header {
            height: 64px;
            font-size: @font-size-UI18;
            font-weight: bold;
            display: flex;
            align-items: center;
            padding-left: 24px;
            padding-right: 24px;
            background-color: @white;
            border-radius: 4px;
            &-title {
                white-space: nowrap;
            }
            &.is-expanding,
            &.is-expanded {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            .header-info {
                margin-left: auto;
                color: @gray-500;
                font-size: @font-size-UI14;
                display: inline;
                font-weight: normal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                flex-basis: 50%;
                min-width: 0;
                text-align: right;
                &.error {
                    color: @red-500;
                }
            }
        }
        .is-expanding,
        .is-expanded {
            &.box-header--error {
                .box-counter {
                    background-color: fade(@red-500, 30);
                    color: @red-500;
                    svg {
                        fill: @red-500;
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
        .collapsable-item-container {
            margin-left: -10px;
            margin-right: -10px;
        }
        .collapsable-item-label {
            display: flex;
            flex: 1;
            align-items: center;
        }
        &-content-wrapper {
            background-color: fade(@gray-500, 8);
            border-radius: 6px;
            padding: 0 10px 10px;
        }
        &-content {
            padding: 50px;
            background-color: @white;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            &.send {
                padding: 32px 60px 21px;
            }
            .send-text {
                text-align: center;
                font-weight: bold;
                color: @black;
                margin-top: 33px;
                .small {
                    margin-top: 10px;
                    color: @gray-500;
                }
            }
            .envelope-icon-wrapper {
                position: relative;
                width: 63px;
                margin: 0 auto;
            }
            .envelope-icon {
                display: block;
                width: 63px;
            }
            .mark-icon {
                display: block;
                width: 40px;
                position: absolute;
                bottom: -8px;
                right: -25px;
                &.success {
                    width: 48px;
                    bottom: -10px;
                    right: -33px;
                }
            }

            & > * + * {
                margin-bottom: 0;
            }
        }
    }
    .form-group {
        label {
            color: @black;
            font-size: @font-size-UI14;
        }
    }

    .audience {
        .filters {
            padding-left: 20px;
            padding-right: 20px;
            &.filter-type {
                padding-right: 0;
                .filter-type-label {
                    flex-basis: 150px;
                }
                .icon svg:first-child {
                    margin-left: 0;
                }
            }
        }
        .filters-and-results {
            background-color: #f7f8fc;
            position: relative;
            .filter-wrapper {
                border: solid 1px @athens-gray;
                &--hidden {
                    max-height: 0;
                    overflow: hidden;
                    box-shadow: none;
                    border: none;
                }
            }
            .add-filter-dropdown {
                position: absolute;
                right: 0;
                top: -58px;

                .dropdown__menu {
                    top: initial;
                    bottom: 100%;
                }
            }
            .btn-link {
                padding: 6px 0;
                min-width: auto;
            }
        }
        .results {
            min-height: 116px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            font-size: @font-size-UI16;
            text-align: center;
            svg {
                width: 24px;
                height: 24px;
            }
            &--success {
                color: @primary-blue-500;
                &:hover {
                    .results__text {
                        text-decoration: underline;
                    }
                }
            }
            strong {
                font-size: @font-size-H24;
                font-weight: bold;
                display: block;
                text-align: center;
            }
            &--failed svg {
                fill: @red-500;
            }
            &--info {
                display: block;
                font-size: @font-size-UI14;
                line-height: 1.43;
                color: @gray-500;
            }
        }
    }
}

.mailing-new-creator-wrapper {
    overflow: visible;
    display: block;

    .audience .filters-and-results .add-filter-dropdown {
        top: calc(100% + 6px);
        right: 12px;
    }

    .audience .filters.filter-type {
        max-width: 180px;
    }

    .filters .filter-item .filter .icon > svg {
        width: 20px;
        height: 20px;
    }

    .filters.filter-type .icon {
        white-space: normal;
    }

    .filter-text,
    .filter-type-label {
        font-size: 14px;
    }
}
