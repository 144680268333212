@import '../../../shared/components/design';

body {
    &.sandbox,
    &.simulate-visitor {
        background: rgb(228, 230, 235) url('background.svg') no-repeat center 140px;
        @media (max-width: 1200px) {
            background-size: calc(100% - 60px);
        }
        p {
            padding: 60px;
            text-align: center;
            font-size: 16px;
            color: @gray-500;
        }
    }
    &.simulate-visitor {
        background-position: center 50px;
    }
}
