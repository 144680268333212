@import '../../../../../media-to-refactor/bootstrap/variables.less';

.bots__launcher .setup {
    position: relative;
    flex: 1;
    display: flex;
    &--editButtonMenuOpened {
        .botSectionSetup__buttons {
            margin-bottom: 50px;
        }
    }
    @media screen and (max-height: 749px) {
        padding-bottom: 0;
    }
    &__column {
        min-height: calc(100vh - 213px);
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: relative;
        margin-top: 30px;
        margin-right: -45px;
        flex: 1;
    }
    &__container {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        align-items: center;
        margin: 0 30px;
        background: url('../../creator/assets/creatorBG.png');
    }
    &__save {
        display: flex;
        align-items: flex-start;
        flex: 0 0 auto;
        background-color: @white;
        justify-content: center;
        box-shadow: 0 12px 30px 0 rgba(51, 69, 96, 0.24);
        position: fixed;
        left: calc(60px + 16px);
        right: 16px;
        bottom: 16px;
        border-radius: 5px;
        transition: bottom 0.5s ease-in-out 0.3s;
        z-index: 5;
        &--hidden {
            bottom: -100px;
        }
    }
    &__saveBtn {
        opacity: 1;
        transition: opacity 0.5s linear;
        &--hidden {
            opacity: 0;
            transition: opacity 0.5s linear 2s;
        }
    }
}
