@import '../../../../shared/components/design';
.dropdown__list-edit-filter {
    .dropdown__item {
        padding: 0 8px;
        .radio {
            margin-bottom: 4px;
            label {
                padding-left: 0;
            }
        }
        &.no-radio {
            label {
                &:before {
                    display: none;
                }
            }
            input {
                margin-left: 0;
            }
        }
    }
    .edit-filter-apply {
        margin-top: 16px;
        padding-top: 4px;
        box-shadow: 0px -1px 0 0 #e9ecef;
        text-align: center;
        display: flex;
        button {
            width: 100%;
            color: @primary-blue-500;
            font-size: @font-size-UI16;
            cursor: pointer;
            border: 0;
            background-color: white;
            padding: 6px;
        }
    }
    label {
        width: 100%;
    }
    .type-secondary.select-container {
        width: calc(100% - 26px);
        margin-left: 26px;
    }
    .no-radio .type-secondary.select-container {
        width: 100%;
        margin-left: 0;
    }
    input:not([role='combobox']) {
        width: calc(100% - 26px);
        margin-left: 26px;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    .error {
        .error-label {
            color: #ff2439;
            float: right;
            margin-right: 4px;
            margin-left: auto;
        }
    }
}
