@import '../../../../../../../shared/components/design';

@royalBlue: #2d78e9;
@dodgerBlue: #09bbff;
@blueHaze: #c6ccdb;
@heather: #b3b9cd;
//
@botButtonBorder: @royalBlue;
@addButtonBackground: @dodgerBlue;
@buttonContextButtons: @heather;
@inactiveButtonColor: @blueHaze;
@addButtonColor: @dodgerBlue;
.buttonBar {
    border-radius: 3px;
    display: flex;
    flex: 0 0 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 700px;
    min-height: 86px;
    flex-direction: row;
    position: relative;
    z-index: 2;
    &__addNew {
        background-color: @addButtonColor;
        outline: 0;
        border-radius: 3px;
        padding: 6px 20px 8px;
        color: @white;
        font-size: @font-size-UI16;
        cursor: pointer;
        border: 1px solid transparent;
        &:active {
            border: 1px solid darken(@addButtonColor, 20%);
        }
        @media screen and (max-width: 1565px) {
            display: none;
        }
        &Text {
            display: inline-block;
            margin-right: 20px;
            max-width: 210px;
            font-size: @font-size-UI16;
            @media screen and (max-width: 1565px) {
                max-width: 320px;
            }
        }
    }
    &--disableContextButtons {
        .buttonBar__button {
            &DragHandle {
                display: none;
            }
            &Delete {
                display: none;
            }
        }
    }
    ul {
        &:before {
            content: '';
            width: ~'calc(100% - 20px)';
            height: ~'calc(100% - 20px)';
            display: block;
            position: absolute;
            top: 10px;
            left: 10px;
            border-radius: 3px;
        }
        // Tutorial purposes
        padding: 0px 25px 25px;
        margin: 10px -10px;
    }
    &__list {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }
    &__button {
        display: inline-flex;
        font-size: 15px;
        border-radius: 25px;
        line-height: 27px;
        padding: 0 20px;
        margin: 6px 5px;
        height: 38px;
        outline: none;
        max-width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        justify-content: center;
        flex-direction: column;
        &--ghost {
            opacity: 0.2;
        }
        &Wrapper {
            display: inline-flex;
            user-select: none;
            margin: 0 8px 0 8px;
            position: relative;
        }
        &:hover {
            .buttonBar__buttonDragHandle {
                opacity: 1;
                pointer-events: auto;
                cursor: grab;
                cursor: -webkit-grab;
                &:focus,
                &:active {
                    cursor: grabbing;
                    cursor: -webkit-grabbing;
                }
            }
            .buttonBar__buttonToggle {
                opacity: 1;
                pointer-events: auto;
            }
        }
        &Toggle {
            transition: opacity 0.2s ease-in-out;
            pointer-events: none;
            cursor: pointer;
            opacity: 0;
            padding: 0;
            position: absolute;
            width: 26px;
            height: 26px;
            border-radius: 25px;
            display: flex;
            background-color: @gray-100;
            align-items: center;
            justify-content: center;
            top: 0;
            right: -5px;
            border: 0;
            &:focus,
            &:active {
                outline: 0;
            }
            &:before {
                font-family: 'Ionicons', sans-serif;
                display: inline-flex;
                font-size: 15px;
                color: @buttonContextButtons;
            }
        }
        &DragHandle {
            transition: opacity 0.2s ease-in-out;
            pointer-events: none;
            cursor: default;
            opacity: 0;
            position: absolute;
            width: 26px;
            height: 18px;
            display: flex;
            border-radius: 2px;
            background-color: @gray-100;
            align-items: center;
            justify-content: center;
            bottom: -2px;
            left: ~'calc(50% - 13px)';
            &:before {
                font-family: 'Ionicons', sans-serif;
                content: '\f20e';
                display: inline-flex;
                font-size: @font-size-UI16;
                color: @buttonContextButtons;
            }
        }
        &--bot {
            border: 1px solid @botButtonBorder;
            color: @botButtonBorder;
            background: @gray-100;
        }
        &--addBot {
            background: #d0e4f9;
            border-color: #d0e4f9;
            cursor: pointer;
        }
        &--addNewButton {
            border: 1px solid @addButtonBackground;
            color: @white;
            background: @addButtonBackground;
            &:before {
                content: '\f218';
                font-family: 'Ionicons', sans-serif;
                color: @white;
                margin-right: 10px;
            }
        }
        &--active {
            .buttonBar__buttonToggle {
                &:before {
                    content: '\f128';
                }
            }
        }
        &--offlineDisabled {
            border-style: dashed;
        }
        &--inactive {
            color: @inactiveButtonColor;
            border-color: @inactiveButtonColor;
            .buttonBar__buttonToggle {
                &:before {
                    content: '\f120';
                }
            }
        }
    }
}
