@import '../../../shared/components/design';

body > img:not([src*='tidio']) {
    display: none;
}

#app-content-wrapper {
    flex: 1;
    background-color: var(--surface-color);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    max-width: calc(100% - 65px);
}

#app-content {
    flex: 1;
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    > section {
        padding: 20px;
        will-change: transform;
        &.active {
            display: block;
        }
    }
    .mobile & {
        height: 0;
    }
}
