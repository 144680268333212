@import '../../../../shared/components/design';
.import-shopify {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    opacity: 0;
    transform: translateX(10px);
    transition: all 0.3s;
    &--visible {
        opacity: 1;
        transform: translateX(0);
    }
    &__content {
        width: 240px;
        border-radius: 4px;
        background-color: @white;
        padding: 24px;
        box-shadow: 0 8px 24px 0 rgba(53, 63, 81, 0.16);
        color: @black;
        position: relative;
        .icon-close {
            border: none;
            box-shadow: none;
            background-color: @white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            border-radius: 4px;
            svg {
                fill: @gray-500;
                opacity: 0.64;
                width: 18px;
                height: 18px;
            }
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 40px;
            cursor: pointer;
            outline: none;
        }
        .icon-shopify {
            width: 30px;
            height: 30px;
        }
        .header {
            font-size: @font-size-UI16;
            font-weight: bold;
            margin-top: 10px;
            margin-bottom: 6px;
        }
        .text {
            font-size: @font-size-UI14;
            padding-bottom: 16px;
        }
        &:before {
            content: '';
            width: 10px;
            height: 10px;
            background-color: white;
            position: absolute;
        }
        &--left:before {
            transform: rotate(45deg);
            left: -5px;
            top: 36px;
        }
        &--top:before {
            transform: rotate(45deg);
            top: -5px;
            left: 36px;
        }
        &--none:before {
            display: none;
        }
    }
}
