@import '../design';

@boxWidth: 280px;
@heather: #b3b9cd;
@mystic: #dde2ea;
@regentGrey: #8791a5;
@dodgerBlue: #0abcff;
@buttonContextButtons: @heather;
@removeIconBackground: @mystic;
@removeIconColor: @regentGrey;
@spinnerColor: @dodgerBlue;
@imagePlaceholderBorder: #d3dbe5;
@imagePlaceholderIcon: #8796af;

.dropdownBS {
    box-shadow: 0 8px 28px 0 @oxford-blue16;
    background: @white;
    position: absolute;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0;
    padding: 8px;
    border-radius: 8px;
}

// Animations
.spin(@duration) {
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    animation-name: spin;
    animation-duration: @duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.roundButton {
    border: 0;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: 36px;
    height: 36px;
    &:before {
        font-family: 'Ionicons', sans-serif;
        display: inline-flex;
        font-size: @font-size-H24;
    }
}

.boxShadow {
    box-shadow: 0 3px 6px 0 @oxford-blue8;
}

.botSectionSetup__messages,
.conversationMessages,
.singleCard,
.singleButtonsTemplate {
    width: @boxWidth;
    @borderRadius: 8px;
    margin: 10px auto 0;
    z-index: 3;
    position: relative;
    &AddNew {
        cursor: pointer;
        width: @boxWidth;
        background-color: @white;
        border: 0;
        border-radius: 8px;
        outline: none;
        font-size: 28px;
        color: @primary-blue-500;
        box-shadow: 0 2px 6px rgba(@primary-blue-900, 0.12); // This is color from shadows.ELEVATION_6_12
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        svg {
            width: 24px;
            height: 24px;
            fill: currentColor;
        }
    }
    ul {
        list-style: none;
        padding: 0;
        position: relative;
        li {
            border-radius: 8px;
            box-shadow: 0 2px 6px rgba(@primary-blue-900, 0.12); // This is color from shadows.ELEVATION_6_12
            position: relative;
            &.emojiMartActive {
                z-index: 1;
            }
        }
    }
    .plusButton {
        position: relative;

        li {
            list-style-type: none;
            box-shadow: none;
        }
        &__Dropdown {
            .dropdownBS();
            right: -140px;
            top: 0;
            z-index: 4;

            &Text button,
            &Image button {
                min-width: 110px;
                padding-left: 38px;
                margin: 0;
                text-align: left;
                font-size: @font-size-UI14;
                line-height: @line-height-UI14;
                border: 0;
                outline: none;
                display: flex;
                align-items: center;
                min-height: 36px;
                border-radius: 8px;
                &:hover {
                    cursor: pointer;
                    background-color: @primary-blue-100;
                    color: @primary-blue-900;
                }
            }
            &Text button {
                background: url('textFormat.svg') no-repeat 6px 8px;
                background-size: 22px;
            }
            &Image button {
                background: url('image.svg') no-repeat 6px 8px;
                background-size: 22px;
            }
        }
    }
    .messages {
        &__imageUploadOverlay {
            opacity: 0;
            transition: all 0.2s ease-in-out;
            width: ~'calc(100% - 105px)';
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8);
            position: absolute;
            border-radius: @borderRadius;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            min-height: 45px;
            color: @white;
            font-size: @font-size-UI16;
            &:focus {
                outline: 0;
            }
            svg {
                fill: currentColor;
                margin-right: 10px;
                width: 32px;
                height: 32px;
            }
            &:hover {
                opacity: 1;
            }

            .reuploadButton,
            .deleteButton {
                background: transparent;
                color: @white;
                border: 0;
                text-align: center;
                cursor: pointer;
                outline: none;
                svg {
                    display: inline-block;
                    margin: 0;
                }
                span {
                    display: block;
                    font-size: 12px;
                }
            }
            .reuploadButton {
                margin-right: 20px;
            }
        }
        &__item {
            &Content {
                min-width: 280px;
                border-radius: @borderRadius;
                background-color: @white;
                .boxShadow();
                &:focus,
                &:active {
                    outline: 0;
                }
                &--loading {
                    background-color: @white;
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    justify-items: center;
                    align-items: center;
                    &:before {
                        font-family: 'Ionicons', sans-serif;
                        content: '\f29d';
                        color: @spinnerColor;
                        .spin(2500ms);
                        font-size: 60px;
                        position: absolute;
                    }
                    img {
                        display: none;
                    }
                    .messages__imageUploadOverlay {
                        display: none;
                    }
                }
                &--image {
                    min-height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    img {
                        border-radius: @borderRadius;
                        max-width: @boxWidth;
                    }
                }
            }
            &DragHandle {
                transition: all 0.2s ease-in-out;
                opacity: 0;
                transform: translateX(60px);
                .roundButton();
                &--hidden {
                    visibility: hidden;
                }
                &:before {
                    content: '\f20e';
                    color: #aeb9d1;
                }
            }
            &Delete {
                &--hidden {
                    visibility: hidden;
                }
                transition: all 0.2s ease-in-out;
                opacity: 0;
                transform: translateX(-60px);
                .roundButton();
                background-color: @removeIconBackground;
                &:before {
                    font-size: @font-size-H20;
                    color: @removeIconColor;
                    content: '\f253';
                }
            }
            &ImagePlaceholder {
                height: 170px;
                width: 250px;
                margin: 15px;
                padding: 30px 20px;
                border: 2px dashed @imagePlaceholderBorder;
                border-radius: 8px;
                display: flex;
                font-size: @font-size-UI16;
                text-align: center;
                flex-direction: column;
                justify-content: flex-end;
                &:before {
                    font-family: 'Ionicons', sans-serif;
                    font-size: 50px;
                    content: '\f148';
                    color: @imagePlaceholderIcon;
                }
                &.hasError {
                    border-color: @red-500;
                }
            }
            &Wrapper {
                margin: 15px 0;
                transform: translateX(-52px);
                width: @boxWidth + 105px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                position: relative;
                .TextInputWrapper {
                    padding: 5px;
                    background: @white;
                    border-radius: 8px;
                    textarea {
                        font-weight: normal;
                        font-size: @font-size-UI16;
                        line-height: @line-height-UI16;
                        font-family: euclidcircularb, sans-serif;
                        padding: 11px 15px 40px;
                        display: block;
                    }
                }
                &:hover {
                    .messages__itemDragHandle,
                    .messages__itemDelete {
                        opacity: 1;
                    }
                    .messages__itemDragHandle {
                        transform: translateX(0px);
                    }
                    .messages__itemDelete {
                        transform: translateX(0px);
                    }
                }
                &--chosen {
                    .messages__itemDelete {
                        visibility: hidden;
                    }
                }
            }
        }
    }
}
