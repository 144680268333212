@import '../design';
@dodgerBlue: #0abcff;
@heather: #b3b9cd;

.TextInputWrapper {
    position: relative;
    .emoji-mart {
        position: absolute;
        left: 327px;
        top: 0;
        z-index: 5;
    }
    &.withEmojiOrVariablesPicker {
        textarea {
            padding-bottom: 32px;
        }
    }
    input,
    textarea:not(.noOverrideStyles) {
        font-family: euclidcircularb, sans-serif;
        outline: none;
        user-select: text;
        padding: 5px 12px;
        font-size: @font-size-UI16;
        font-weight: 400;
        border-radius: 8px;
        position: relative;
        background-color: @white;
        width: 100%;
        box-sizing: border-box;
        border: 2px solid transparent;
        &.borderVisible {
            border-color: @gray-200;
        }
        &:hover {
            border-color: @gray-300;
        }
        &:focus {
            border-color: @primary-blue-500;
        }
        &.hasError {
            border-color: @red-500;
        }
    }
    textarea {
        resize: none;
        overflow: hidden;
        color: @black;
        &::placeholder {
            color: #d7d7d7;
            font-style: italic;
            font-size: @font-size-UI16;
        }
    }
}
