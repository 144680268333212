@import '../../../media-to-refactor/bootstrap/variables.less';

.btn.btn-black-week {
    background-color: #e13d55;
    border-color: #e13d55;
    border-radius: 3px;
    color: @white;
    font-weight: bold;

    &:hover {
        background-color: darken(#e13d55, 10);
        border-color: darken(#e13d55, 10);
        color: @white;
    }

    &--monday {
        background-color: #e72869;
        border-color: #e72869;
        color: @white;

        &:hover {
            background-color: darken(#e72869, 10);
            border-color: darken(#e72869, 10);
            color: @white;
        }
    }
}

#app-content-header {
    height: 58px;
    flex: 0 0 58px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    background-color: var(--surface-color);
    z-index: 12;
    position: relative;
    padding-left: 24px;
    padding-right: 12px;

    h1 {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: auto;
        font-weight: 500;
        font-size: @font-size-H20;
        line-height: @line-height-H20;
        letter-spacing: @letter-spacing-H20;
        display: inline-flex;
        align-items: center;
        max-width: 60%;
        & > span {
            margin-right: 10px;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &.label {
                font-size: 10px;
                margin-left: 4px;
                padding-left: 3px;
                padding-right: 3px;
            }
            &.btn-content {
                display: flex;
                align-items: center;
            }
        }
        i {
            color: @gray-200;
            margin-right: 16px;
            font-size: 29px;
            vertical-align: sub;
            margin-left: 4px;
            position: relative;
            width: 26px;
            height: 31px;
            display: inline-block;
            text-align: center;
            &.svg-icon {
                margin-right: 12px;
                svg {
                    fill: @gray-300;
                    width: 32px;
                    height: 32px;
                }
            }
        }
        .svg-icon {
            display: flex;
            align-items: center;
            margin-right: 12px;
            margin-left: 0;
            fill: @gray-300;
            width: 32px;
            height: 32px;
        }
        .avatar {
            width: 32px;
            height: 32px;
            display: flex;
            flex: 0 0 32px;
            align-items: center;
            justify-content: center;
            margin-top: -1px;
            font-size: 19px;
            line-height: 20px;
            outline: none;
            margin-right: 14px;

            &.offline,
            &.online {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    width: 12px;
                    height: 12px;

                    border: solid 2px #fff;
                    position: absolute;
                    right: -3px;
                    bottom: -3px;
                    border-radius: 50%;
                }
            }

            &.offline {
                &:after {
                    box-shadow: inset 0 0 0 2px @red-500;
                }
            }
            &.online:after {
                background: @brand-success;
            }
        }
        .btn-default {
            &:before {
                color: @gray-darker;
            }
        }
    }
    .btn.btn-hollow,
    .btn.btn-black-week {
        flex: 0 0 auto;
    }
}
