@import '../../../../shared/components/design';
.email-consent {
    color: @black;
    &-wrapper {
        min-width: 176px;
    }
    &-trigger {
        height: 20px;
        min-width: 56px;
        border-radius: 4px;
        box-shadow: 0 1px 2px 0 rgba(0, 35, 91, 0.24);
        background-color: @white;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        font-size: @font-size-UI12;
        line-height: @line-height-UI12;
        font-weight: 500;
        padding-left: 8px;
        color: @gray-500;
        cursor: pointer;
        vertical-align: top;
        &--unsubscribed {
            color: #c85a01;
        }
        &--subscribed {
            color: @green-600;
        }
        &--readonly {
            display: inline-flex;
            padding-left: 6px;
            padding-right: 6px;
        }
        .isvg {
            display: flex;
            align-items: center;
        }
    }
    &-info {
        padding: 4px 16px 12px;
        border-bottom: 1px solid #e8eaee;
        color: @gray-500;
        font-size: @font-size-UI14;
        line-height: 20px;
        margin-bottom: 8px;
        margin-left: -8px;
        margin-right: -8px;
        white-space: initial;
        p {
            margin: 0;
        }
    }
    &-source {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        align-items: baseline;
    }
    &-date,
    &-ip {
        font-size: @font-size-UI12;
        font-weight: normal;
    }
    &-date {
        margin-left: 20px;
    }
    &-actions {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }
    &-description {
        max-width: 220px;
        margin-top: 6px;
        padding: 12px 20px 12px 44px;
        font-size: @font-size-UI12;
        color: @gray-500;
        background-color: @gray-50;
        margin-bottom: -8px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        position: relative;
        svg {
            position: absolute;
            top: 13px;
            margin-right: 8px;
            margin-left: -26px;
        }
    }
    .dropdown__menu {
        display: none;
    }
}
