@import '../../../media-to-refactor/bootstrap/variables.less';
@import '../../../media-to-refactor/bootstrap/mixins/vendor-prefixes.less';
@import '../../../media-to-refactor/bootstrap/mixins/clearfix.less';

@keyframes scale-up {
    0% {
        opacity: 0;
        transform: scale(0.8) translateY(20px);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.chat-talk {
    flex: 1;
    height: 100%;
    width: 100%;
    min-width: 0;
    outline: none;
    .conversation {
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
    }
    .log-wrapper {
        color: @dark-lighter;
        font-style: italic;
        text-align: center;
        padding: 8px;
        .user-select(none);
        cursor: default;
        overflow-wrap: anywhere;
    }
    .botLog {
        border: 1px solid #d7dbe0;
        border-radius: 5px;
        margin: 8px 0 5px 63px;
        display: inline-block;
        padding: 10px 20px 10px 14px;
        position: relative;
        span.icon {
            display: inline-block;
            width: 24px;
            height: 24px;
            background-size: 24px;
            display: inline-block;
            position: absolute;
        }
        span.text {
            display: inline-block;
            margin-left: 34px;
            line-height: 24px;
            font-weight: bold;
        }
        &.botAppStarted {
            .icon {
                background-image: url('../../../public/media/img/panel/visitor/botInConversationIcons/bot.svg');
            }
        }
        &.botAppStopped {
            .icon {
                background-image: url('../../../public/media/img/panel/visitor/botInConversationIcons/stop.svg');
            }
        }
        &.botAppFailed {
            .icon {
                background-image: url('../../../public/media/img/panel/visitor/botInConversationIcons/error.svg');
            }
        }
        &.botAppSuccess {
            .icon {
                background-image: url('../../../public/media/img/panel/visitor/botInConversationIcons/success.svg');
            }
        }
        &.botAppCancelled {
            .icon {
                background-image: url('../../../public/media/img/panel/visitor/botInConversationIcons/cancel.svg');
            }
        }
        &.botAppTransferred {
            .icon {
                background-image: url('../../../public/media/img/panel/visitor/botInConversationIcons/transferred.svg');
            }
        }
    }
    .message-wrapper {
        position: relative;
        padding-left: 56px;
        padding-right: 12px;
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 8px;
        display: block;
        width: 100%;
        overflow-wrap: anywhere;
        // Clearfix for all messages.
        &:before,
        &:after {
            clear: both;
            content: '';
            display: table;
        }
        &.typing > span {
            font-style: italic;
        }
        &.typing.in-progress > span:not(.typing-blurred):after {
            content: '\2026';
        }
        &.preview {
            color: @dark-lighter;
        }
        @keyframes blink-animation {
            0% {
                background: #ffeaa9;
            }
            100% {
                background: transparent;
            }
        }
        &.highlight {
            animation: blink-animation 4s linear;
        }
        .avatar {
            margin-right: 14px;
            margin-top: 4px;
            position: absolute;
            left: 12px;
        }
        &.hover,
        &:hover {
            background-color: @gray-lighter;

            &:not(.with-avatar) span:before {
                width: 40px;
                height: 14px;
                overflow: hidden;
                color: @dark-lighter;
                font-size: @font-size-UI12;
                content: attr(data-time);
                position: absolute;
                top: 10px;
                left: 2px;
                text-align: right;
            }
        }
        header {
            p {
                margin: 0;
            }
            strong {
                margin-right: 10px;
                &.deleted-operator {
                    color: @gray-500;
                }
            }
            span {
                color: @dark-lighter;
                font-size: @font-size-UI12;
            }
        }
        .image,
        .message {
            float: left;
        }
        span {
            white-space: pre-line;
            &.typing-blurred {
                display: inline-flex;
                align-items: center;
                flex-wrap: wrap;
                a {
                    font-style: normal;
                    margin-left: 4px;
                }
                span {
                    width: 40px;
                    height: 8px;
                    background: @gray-darker;
                    display: inline-block;
                    border-radius: 8px;
                    margin-right: 4px;
                    animation: typing-blurred1 1s;
                    overflow: hidden;
                    &:nth-of-type(2n + 2) {
                        width: 20px;
                        animation: typing-blurred2 1s;
                    }
                    &:nth-of-type(3n + 3) {
                        width: 50px;
                        animation: typing-blurred3 1s;
                    }
                    &:nth-of-type(4n + 4) {
                        width: 40px;
                        animation: typing-blurred4 1s;
                    }
                    &:nth-of-type(5n + 5) {
                        width: 10px;
                        animation: typing-blurred5 1s;
                    }
                    &:nth-of-type(6n + 6) {
                        width: 20px;
                        animation: typing-blurred6 1s;
                    }
                }
            }
        }
        @keyframes typing-blurred1 {
            0% {
                opacity: 0;
                width: 0px;
            }
            100% {
                opacity: 1;
                width: 40px;
            }
        }
        @keyframes typing-blurred2 {
            0% {
                opacity: 0;
                width: 0px;
            }
            100% {
                opacity: 1;
                width: 20px;
            }
        }
        @keyframes typing-blurred3 {
            0% {
                opacity: 0;
                width: 0px;
            }
            100% {
                opacity: 1;
                width: 50px;
            }
        }
        @keyframes typing-blurred4 {
            0% {
                opacity: 0;
                width: 0px;
            }
            100% {
                opacity: 1;
                width: 40px;
            }
        }
        @keyframes typing-blurred5 {
            0% {
                opacity: 0;
                width: 0px;
            }
            100% {
                opacity: 1;
                width: 10px;
            }
        }
        @keyframes typing-blurred6 {
            0% {
                opacity: 0;
                width: 0px;
            }
            100% {
                opacity: 1;
                width: 20px;
            }
        }
        @keyframes spinner-animation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        .spinner {
            margin: 10px 0;
            display: inline-block;
            width: 24px;
            height: 24px;
            background-image: url(../../../public/media/img/panel/visitor/spinner.png);
            background-size: 24px;
            animation: spinner-animation 1s linear infinite;
        }
        iframe {
            width: 100%;
            height: 0;
            border: none;
            overflow: auto;
            // Background
            &::-webkit-scrollbar-track {
                background-color: @gray-100;
            }
            // Scroll
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #d7dde4;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #d7dde4;
            }
        }
    }
    .notifications-bar {
        flex: 0 0 32px;
    }
}
