@import '../design';
.select-container {
    &.with-error {
        .select-content__control {
            border-color: @red-500;
            &:hover {
                border-color: @red-500;
            }
            &.type-primary {
                box-shadow: 0 2px 0 0 @red-500;
                &.size-xl {
                    box-shadow: 0 3px 0 0 @red-500;
                }
            }
        }
    }
    padding: 0;
    &.type-primary {
        margin-bottom: 4px;
    }
    .select-content {
        &__control {
            padding: 0 5px 0 12px;
            cursor: pointer;
            border: 2px solid @gray-200;
            min-height: auto;
            box-shadow: none;
            align-items: stretch;
            &.size-m {
                height: 34px;
            }
            &.size-l {
                height: 38px;
            }
            &.size-xl {
                height: 52px;
                padding: 0 5px 0 14px;
            }
            &:hover {
                border-color: @gray-300;
            }
            &.type-primary {
                padding: 0;
                border: none;
                border-radius: 0;
                box-shadow: 0 2px 0 0 #d9dce4;
                &.size-xl {
                    box-shadow: 0 3px 0 0 #d9dce4;
                }
            }
            &--menu-is-open {
                border-color: @primary-blue-500;
                &:hover {
                    border-color: @primary-blue-500;
                }
                svg {
                    transform: rotate(180deg);
                }
                &.type-primary {
                    box-shadow: 0 2px 0 0 @primary-blue-500;
                    &.size-xl {
                        box-shadow: 0 3px 0 0 @primary-blue-500;
                    }
                }
            }
            &--is-disabled {
                pointer-events: auto;
                cursor: not-allowed;
                background-color: #eceef1;
                &.type-primary {
                    background-color: transparent;
                    box-shadow: 0 2px 0 0 #eceef1;
                    &.size-xl {
                        box-shadow: 0 3px 0 0 #eceef1;
                    }
                }
                .select-content__dropdown-indicator svg {
                    fill: @gray-500;
                }
            }
        }
        &__value-container {
            padding: 0;
            margin: 0;
        }
        &__indicator-separator {
            display: none;
        }
        &__dropdown-indicator {
            padding: 0;
        }
        &__single-value {
            color: @black;
            margin: 0;
            font-weight: normal;
            &.size-m {
                font-size: @font-size-UI14;
            }
            &.size-l {
                font-size: @font-size-UI16;
            }
            &.size-xl {
                font-size: @font-size-UI16;
            }
            &--is-disabled {
                color: @gray-500;
            }
        }
        &__menu {
            border: none;
            box-shadow: 0 8px 24px 0 @oxford-blue32;
            margin-top: 4px;
            border-radius: 8px;
            &.type-primary {
                margin-top: 8px;
            }
        }
        &__menu-list {
            border: none;
            max-height: 208px;
            padding: 8px;
        }
        &__menu-notice {
            color: @black;
            text-align: left;
            &.size-m {
                font-size: @font-size-UI14;
            }
            &.size-l {
                font-size: @font-size-UI16;
            }
            &.size-xl {
                font-size: @font-size-UI16;
            }
        }
        &__option {
            color: @black;
            cursor: pointer;
            padding: 8px;
            font-size: @font-size-UI14;
            line-height: 20px;
            border-radius: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            &--is-disabled {
                color: @gray-500;
                cursor: not-allowed;
            }
        }
        &__placeholder {
            margin: 0;
            color: @gray-500;
            &.size-m {
                font-size: @font-size-UI14;
            }
            &.size-l {
                font-size: @font-size-UI16;
            }
            &.size-xl {
                font-size: @font-size-UI16;
            }
        }
        &__multi-value {
            margin: 4px 5px 0 0;
            background-color: rgba(0, 126, 255, 0.08);
            border: 1px solid rgba(0, 126, 255, 0.24);
            &__label {
                padding: 0 6px;
                color: #007eff;
                border-right: 1px solid rgba(0, 126, 255, 0.24);
            }
            &__remove {
                color: #007eff;
                &:hover {
                    color: #007eff;
                    background-color: rgba(0, 126, 255, 0.08);
                }
            }
        }
        &__input {
            &.size-m {
                font-size: @font-size-UI14;
            }
            &.size-l {
                font-size: @font-size-UI16;
            }
            &.size-xl {
                font-size: @font-size-UI16;
            }
        }
        &__group-heading {
            margin-bottom: 8px;
            color: @gray-500;
            font-size: @font-size-UI12;
            padding-left: 14px;
            letter-spacing: 0.7px;
        }
    }
    &.is-high {
        .select-content {
            &__menu {
                top: -50px;
            }
            &__menu-list {
                max-height: 450px;
            }
        }
    }
}
