@import '../../../../shared/components/design';
.contacts {
    .no-results {
        text-align: center;
        font-size: 22px;
        padding-top: 170px;
        padding-bottom: 115px;
        background-image: url('assets/emptyList.svg');
        background-repeat: no-repeat;
        background-size: 80px 80px;
        background-position: 50% 55px;
    }
    .no-subscribers {
        padding-top: 160px;
        padding-left: 100px;
        background-image: url('assets/noSubscribers.png');
        background-repeat: no-repeat;
        background-size: 531px 363px;
        background-position: right 0;
        min-height: 420px;
        @media (max-width: 1440px) {
            padding-left: 80px;
            background-position: calc(100% + 50px) 0;
        }
        @media (max-width: 1279px) {
            background-image: none;
            padding-top: 80px;
            min-height: calc(420px - 160px);
        }
        header {
            font-size: 22px;
            line-height: 40px;
        }
        p {
            font-size: @font-size-UI14;
            display: flex;
            align-items: center;
            line-height: 24px;
            flex-wrap: nowrap;
        }
        a {
            margin-left: 4px;
        }
    }
}
