@import '../../../../../../../shared/components/design';

@dodgerBlue: #4f91fb;
//
@toggleBtnColor: @dodgerBlue;
@btnBorderTop: @gray-100;
.editButtonWindow {
    position: absolute;
    width: 234px;
    background-color: @white;
    min-height: 102px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 8px;
    box-shadow: 0 8px 28px 0 @oxford-blue16;
    transition: all 0.2s ease-in-out;
    margin-bottom: 20px;
    &.withChanges {
        margin-bottom: 80px;
    }
    &__toggleBtn {
        width: 100%;
        padding: 14px 0;
        font-size: @font-size-UI16;
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        color: @toggleBtnColor;
        background: @white;
        border: 0;
        white-space: nowrap;
        border-top: 1px solid @btnBorderTop;
        cursor: pointer;
        &:focus,
        &:active {
            outline: 0;
        }
        &:first-of-type {
            border-radius: 8px 8px 0 0;
        }
        &:last-of-type {
            border-radius: 0 0 8px 8px;
        }
    }
}
