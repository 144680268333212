@import '../../design';
/* DayPicker styles */

.DayPicker {
    display: inline-block;
    font-size: @font-size-UI14;
    color: #101a2c;
    cursor: default;
}

.DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    padding-bottom: 24px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    outline: none;
}

.DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.DayPicker-Month {
    display: table;
    margin: 24px 15px 0;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.DayPicker-NavButton {
    position: absolute;
    top: 22px;
    right: 1.5em;
    left: auto;
    display: inline-block;
    margin-top: 2px;
    width: 24px;
    height: 24px;
    background-position: center;
    background-repeat: no-repeat;
    color: #8b9898;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

.DayPicker-NavButton:hover {
    opacity: 0.8;
}

.DayPicker-NavButton--prev {
    left: 1.5em;
    background-image: url(baseline-arrow_back-24px.svg);
}

.DayPicker-NavButton--next {
    background-image: url(baseline-arrow_forward-24px.svg);
}

.DayPicker-NavButton--interactionDisabled {
    display: none;
}

.DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: left;
}

.DayPicker-Caption > div {
    font-weight: bold;
    font-size: @font-size-UI18;
    text-align: center;
}

.DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
}

.DayPicker-WeekdaysRow {
    display: table-row;
}

.DayPicker-Weekday {
    display: table-cell;
    padding: 0.5em 0.4em;
    color: #6c7d9f;
    text-align: center;
}

.DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
    cursor: default;
}

.DayPicker-Body {
    display: table-row-group;
}

.DayPicker-Week {
    display: table-row;
}

.DayPicker-Day {
    display: table-cell;
    min-width: 35px;
    width: 35px;
    height: 35px;
    border: 1px solid #dde0eb;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    &:focus {
        outline: none;
    }
}

.DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #eaecec;
    color: #8b9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
}

.DayPicker-Footer {
    padding-top: 0.5em;
}

.DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: #4a90e2;
    font-size: 0.875em;
    cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--outside {
    color: #8b9898;
    cursor: default;
    border: none;
}

.DayPicker-Day--disabled {
    color: #6c7d9f;
    cursor: default;
    opacity: 0.5;
}

/* Example modifiers */

.DayPicker-Day--sunday {
    background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
}

.DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(
        .DayPicker-Day--outside
    ):hover {
    background-color: #dae0eb;
}

/* DayPickerInput */

.DayPickerInput {
    display: block;
    input {
        display: block;
        width: 100%;
        height: 34px;
        color: @black;
        border: 2px solid @gray-200;
        border-radius: 8px;
        padding: 0 12px;
        outline: none;
        margin-bottom: 4px;
        font-size: @font-size-UI14;
        line-height: @line-height-UI14;
        cursor: pointer;
        &::placeholder {
            color: @gray-500;
        }
        &:focus {
            border-color: @primary-blue-500;
        }
        &:disabled {
            cursor: auto;
            color: @gray-500;
            border-color: #eceef1;
            background-color: transparent;
        }
    }
}

.DayPickerInput-OverlayWrapper {
    position: relative;
}

.DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    top: 10px;
    z-index: 2;
    background: white;
    border-radius: 8px;
    box-shadow: 0 8px 24px 0 rgba(16, 26, 44, 0.24);
}
