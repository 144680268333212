@import '../../../../shared/components/design';

.botsCreator {
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    overflow: hidden;
    position: relative;
    margin-left: 24px;
    width: calc(100% - 36px);
    height: calc(100% - 13px);
    border-radius: 16px;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @keyframes upDown {
        0%,
        100% {
            transform: translate(-14px, -14px);
        }
        40% {
            transform: translate(-14px, -7px);
        }
    }
    .botsScrollHide {
        overflow: scroll;
        position: absolute;
        top: 0;
        left: 0;
        right: -20px;
        bottom: -20px;
        background-color: @gray-100;
        border-radius: 16px;
        outline: none;
        .botsScroller {
            width: 20000px;
            height: 20000px;
            background: url('assets/creatorBG.png');
            svg {
                display: block;
            }
            .messages__imageUploadOverlay button svg {
                display: inline-block;
            }
        }
    }
    .nodeNotDraggableTo {
        opacity: 0.5;
    }
    .node-menu-label {
        line-height: 16px;
        display: table-cell;
        vertical-align: middle;
        height: 50px;
    }
    .moveNode {
        cursor: grab;
        .moreInfoButton {
            display: none;

            svg {
                fill: @gray-500;
            }
        }
        &:hover {
            .moreInfoButton {
                display: inline;
            }
        }
    }
    .sharp {
        shape-rendering: crispedges;
    }
    .deleteBox {
        background: @white;
        width: 28px;
        height: 28px;
        box-shadow: 0 4px 16px 0 rgba(162, 181, 212, 0.32);
        border-radius: 14px;
    }
    .removeConnectionRect,
    .removeConnectionX {
        pointer-events: none;
    }
    .connectionHovered {
        cursor: pointer;
    }
    .removeConnection {
        margin-left: 9px;
        margin-top: 9px;
        width: 21px;
        height: 21px;
        border-radius: 23px;
        background: @white;
        box-shadow: 0 0 16px 2px rgba(162, 181, 212, 0.32);
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .routeDetailsSuccess,
    .routeDetailsFail,
    .routeDetailsCancelled {
        width: 20px;
        height: 18px;
        display: inline-block;
        background-size: 18px;
        background-repeat: no-repeat;
    }
    .routeDetailsSuccess {
        background-image: url('./components/icons/checkmark.svg');
    }
    .routeDetailsFail {
        background-image: url('./components/icons/close-red.svg');
    }
    .routeDetailsWithLabel {
        color: #3247a6;
        font-weight: bold;
    }
    .routeDetailsCancelled {
        background-image: url('./components/icons/cancel.svg');
    }
    .routeDetailsText,
    .routeDetailsTextWithFlex {
        white-space: nowrap;
    }
    .routeDetailsTextWithFlex {
        display: flex;
    }

    #currentlyDraggedNodeWrongConnectionText {
        width: 125px;
        line-height: 16px;
        text-transform: uppercase;
        color: @white;
        font-family: euclidcircularb, sans-serif;
        font-size: 10px;
        text-align: center;
        font-weight: bold;
        border-radius: 4px;
        pointer-events: none;
        .wrongConnectionNodeText {
            background: #eb5a5a;
            border-radius: 4px;
            padding: 4px;
            pointer-events: all;
        }
    }
}
#menuNodeDropper {
    display: none;
    position: absolute;
    width: 180px;
    height: 85px;
    z-index: 17;
}
.noPointerEvents,
#currentlyDraggedNodeCircle {
    pointer-events: none;
}

.botsCreatorEditBoxContent {
    .editBoxInputText,
    .editBoxInputTextarea {
        width: 100%;
        border: 0;
        box-shadow: 0 3px 0 0 #d9dce4;
        margin-bottom: 4px;
        outline: none;
        font-size: @font-size-H20;
        color: @black;
        &:focus {
            box-shadow: 0 3px 0 0 @primary-blue-500;
        }
    }
    .editBoxInputText {
        height: 46px;
    }
    .editBoxInputTextarea {
        height: 130px;
        resize: vertical;
        padding-bottom: 7px;
    }
    .editBoxVariable {
        color: #2b3544;
        font-weight: 400;
        display: flex;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 7px 8px;
        border-radius: 8px;
        svg {
            margin-right: 12px;
        }
        &.notFound {
            display: flex;
            margin: 0 -8px;
            color: @gray-500;
            background-color: @white;
            line-height: normal;
            pointer-events: none;
            cursor: none;
            white-space: normal;
        }
        &.createNew {
            color: @black;
            border: none;
            width: 100%;
            border-top: 1px solid #dee3e8;
            padding: 14px 22px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            background-color: @white;
            outline: none;
            margin: 0;
            font-size: @font-size-UI14;
            line-height: @line-height-UI14;
            text-align: left;
            &:hover {
                background-color: @primary-blue-100;
                color: @primary-blue-900;
                cursor: pointer;
            }
        }
    }
    .editBoxVariablesSectionTitle {
        font-size: @font-size-UI14;
        color: #6d7a94;
        margin: 8px 8px 2px 8px;
    }
    #react-autowhatever-automation-variables > div:nth-child(2) {
        border-top: 1px dashed #dcdfe2;
    }
    #react-autowhatever-automation-variables .editBoxVariable {
        svg {
            fill: @gray-500;
        }
        &.createNew svg {
            fill: @primary-blue-500;
            margin-right: 12px;
        }
    }
    #react-autowhatever-automation-variables li[aria-selected='true'] .editBoxVariable {
        background-color: @primary-blue-100;
        svg {
            fill: @primary-blue-500;
        }
        &.notFound {
            background-color: @white;
        }
    }
}

.botsCreator {
    .creatorMessages {
        background-color: #f8f9fc;
        width: 100%;
        margin: auto;
        padding: 20px;
        .messages__itemWrapper.textMessage {
            transform: none;
        }
        &.conversationMessages {
            .messages__itemWrapper.textMessage {
                transform: translateX(-4px);
            }
        }
        textarea {
            min-height: 76px;
        }
        .plusButton__Dropdown {
            right: 104px;
            top: 48px;
        }
        ul {
            li {
                box-shadow: none;

                .TextInputWrapper {
                    padding: 5px;
                    background: @white;
                    border-radius: 8px;
                    box-shadow: 0 2px 6px rgba(@primary-blue-900, 0.12); // This is color from shadows.ELEVATION_6_12
                }
                textarea {
                    font-size: @font-size-UI16;
                    line-height: @line-height-UI16;
                    font-family: euclidcircularb, sans-serif;
                    font-weight: normal;
                    padding: 11px 15px 40px;
                    color: @gray-800;
                }
            }
        }
        .messages__itemWrapper {
            transform: translateX(-3px);
            &.textMessage {
                z-index: 2;
            }
            &:hover {
                .messages__itemDragHandle {
                    transform: translateX(11px);
                }
                .messages__itemDelete {
                    transform: translateX(-11px);
                }
            }
        }
    }
}
.nodeLabelMeasurer {
    background-color: #fffffd;
    max-width: 100%;
    padding: 8px 14px;
    font-size: @font-size-UI14;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 3px 16px 0 rgba(0, 44, 128, 0.04);
    word-wrap: break-word;
    pointer-events: none;
    line-height: 18px;
    display: inline-block;
}
.nodeLabelMeasurer {
    width: 150px;
    position: absolute;
    left: -9000px;
    top: -9000px;
}
