@import '../../../../../../../shared/components/design';

.decisionCardMessagesBoxContent {
    min-height: 560px;
    form {
        height: 100%;
    }

    &.botsCreatorEditBoxContent .editBoxField {
        position: relative;
        &.cardsBox {
            height: auto;
        }
    }

    .scrollerSingleCard {
        outline: none;
        margin: 10px 11px 20px 0;
        scroll-snap-align: center;
        transition: opacity 0.3s ease-in-out;
        .deleteCard {
            background-image: url('../../../components/icons/close.svg');
            background-repeat: no-repeat;
            background-size: 19px;
            background-position: center center;
            outline: none;
            cursor: pointer;

            position: absolute;
            top: -8px;
            right: -8px;
            z-index: 5;
            width: 26px;
            height: 26px;
            background-color: @white;
            border-radius: 30px;
            border: 0;
            box-shadow: 0 6px 10px 0 rgba(8, 15, 26, 0.24);
        }
        &.notvisible {
            opacity: 0.5;
            transition: opacity 0.1s ease-in-out;
            .deleteCard {
                display: none;
            }
        }
        &:last-child {
            padding-right: 57px;
        }
    }
    .singleCard {
        background: @white;
        font-family: euclidcircularb, sans-serif;
        width: 282px;
        box-shadow: 0 3px 5px 0 rgba(8, 15, 26, 0.1);
        border: 1px solid #d1d8e3;
        border-radius: 5px;
        .imageMessage {
            .messages {
                &__item {
                    &DragHandle,
                    &Delete {
                        display: none;
                    }
                    &ImagePlaceholder {
                        height: 150px;
                        background: @gray-100 url('assets/uploadimage.svg') no-repeat center center;
                        border-bottom: 0;
                        outline: none;
                        width: 100%;
                        margin: 0;
                        border-radius: 5px 5px 0 0;
                        &:before {
                            content: '';
                        }
                    }
                }
                &__imageUploadOverlay {
                    width: 100%;
                    border-radius: 5px 5px 0 0;
                }
                &__itemContent {
                    box-shadow: none;
                    &--image img {
                        border-radius: 5px 5px 0 0;
                        background-position: center center;
                    }
                    &--loading {
                        height: 150px;
                    }
                    .fakeImage {
                        height: 150px;
                        background-size: cover;
                        border-bottom: 0;
                        outline: none;
                        width: 100%;
                        margin: 0;
                        border-radius: 5px 5px 0 0;
                        background-position: center center;
                        &:before {
                            content: '';
                        }
                    }
                }
            }
        }
        .messages__itemWrapper,
        .TextInputWrapper {
            transform: none;
            margin: 0;
            width: 100%;
            textarea {
                width: calc(100% - 10px);
                margin: 5px;
                &::placeholder {
                    font-style: normal;
                    color: #838b95;
                    font-size: @font-size-UI14;
                }
            }
            li {
                box-shadow: none;
            }
        }
        .titleInputContainer {
            margin-top: -7px;
            position: relative;
            .TextInputWrapper {
                textarea {
                    color: #222a35;
                    &::placeholder {
                        font-size: @font-size-UI16;
                    }
                }
            }
            .titleInput {
                font-size: @font-size-UI16;
                margin-top: 15px;
                min-height: 35px;
                &.hasError {
                    textarea {
                        border-color: @red-500;
                    }
                }
            }
        }
        .subtitleInput {
            font-size: @font-size-UI14;
            min-height: 70px;
            margin-top: -11px;
            &.TextInputWrapper {
                textarea {
                    color: rgba(34, 42, 53, 0.8);
                    border-radius: 5px;
                    font-size: @font-size-UI14;
                }
            }
        }
        .urlInput {
            box-sizing: border-box;
            width: calc(100% - 10px);
            margin: -7px 5px 15px;
            border: 1px solid transparent;
            outline: none;
            padding: 1px 15px;
            font-family: euclidcircularb, sans-serif;
            color: rgba(34, 42, 53, 0.6);
            font-size: @font-size-UI14;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            border-radius: 5px;
            &:hover {
                border-color: @athens-gray;
            }
            &:focus {
                border-color: #0abcff;
            }
            &.hasError {
                border-color: @red-500;
            }
        }
        .buttons {
            width: 100%;
            .singleButtonContainer {
                position: relative;
                z-index: 1;
                &:last-child {
                    input {
                        border-radius: 0 0 5px 5px;
                    }
                }
                .charactersIndicator {
                    display: none;
                }
            }
            .singleButton {
                font-family: euclidcircularb, sans-serif;
                color: rgba(5, 102, 255, 1);
                text-overflow: ellipsis;
                padding: 0 21px;
                &:focus {
                    background: rgba(5, 102, 255, 0.05);
                    cursor: text;
                    & + .charactersIndicator {
                        display: block;
                        bottom: 3px;
                    }
                }
                &.buttonError {
                    color: rgba(235, 90, 90, 1);
                    background: rgba(235, 90, 90, 0.05);
                }
            }
            .addButtonButton {
                color: @gray-500;
                font-size: @font-size-UI16;
                cursor: pointer;
                svg {
                    width: 29px;
                    height: 29px;
                    fill: @primary-blue-500;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 6px;
                }
                span {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            button,
            .singleButton {
                font-size: @font-size-UI16;
                line-height: 46px;
                background: @white;
                width: 100%;
                outline: none;
                text-align: center;
                &:last-child {
                    border-radius: 0 0 5px 5px;
                }
            }
            button {
                border: 0;
                border-top: 1px solid #dee3e8;
            }
        }
    }
}
