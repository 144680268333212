@import '../../../../../../../shared/components/design';

.decisionButtonsBoxContent,
.iframeBoxContent {
    min-height: 560px;
    form {
        height: 100%;
        & > div {
            outline: none;
        }
    }
    &.botsCreatorEditBoxContent .editBoxField {
        height: auto;
    }
    .singleButtonsTemplate {
        background: @white;
        font-family: euclidcircularb, sans-serif;
        width: 282px;
        box-shadow: 0px 3px 5px 0 rgba(8, 15, 26, 0.1);
        border: 1px solid #d1d8e3;
        border-radius: 5px;
        margin: 0 auto 20px auto;
        outline: none;
        .messages__itemWrapper,
        .TextInputWrapper {
            transform: none;
            margin: 0;
            width: 100%;
            textarea {
                width: calc(100% - 10px);
                margin: 5px;
                &::placeholder {
                    font-style: normal;
                    color: #838b95;
                    font-size: @font-size-UI14;
                }
            }
            li {
                box-shadow: none;
            }
        }
        .textInputContainer {
            margin-top: -7px;
            position: relative;
            outline: none;
            .TextInputWrapper {
                textarea {
                    color: #222a35;
                    &::placeholder {
                        font-size: @font-size-UI16;
                    }
                }
            }
            .textInput {
                font-size: @font-size-UI16;
                margin-top: 15px;
                min-height: 35px;
                &.hasError {
                    textarea {
                        border-color: @red-500;
                    }
                }
            }
        }
        .buttons {
            width: 100%;
            .singleButtonContainer {
                position: relative;
                z-index: 1;
                .charactersIndicator {
                    display: none;
                }
            }
            .singleButton {
                font-family: euclidcircularb, sans-serif;
                color: rgba(5, 102, 255, 1);
                text-overflow: ellipsis;
                padding: 0 21px;
                &:focus {
                    background: rgba(5, 102, 255, 0.05);
                    cursor: text;
                    & + .charactersIndicator {
                        display: block;
                        bottom: 3px;
                    }
                }
                &.buttonError {
                    color: rgba(235, 90, 90, 1);
                    background: rgba(235, 90, 90, 0.05);
                }
            }
            .addButtonButton {
                color: @gray-500;
                font-size: @font-size-UI16;
                cursor: pointer;
                svg {
                    width: 29px;
                    height: 29px;
                    fill: @primary-blue-500;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 6px;
                }
                span {
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            button,
            .singleButton {
                font-size: @font-size-UI16;
                line-height: 46px;
                background: @white;
                width: 100%;
                outline: none;
                text-align: center;
                &:last-child {
                    border-radius: 0 0 5px 5px;
                }
            }
            button {
                border: 0;
                border-top: 1px solid #dee3e8;
            }
        }
    }
}
