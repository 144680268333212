@import '../../../../../../media-to-refactor/bootstrap/variables.less';

@screen-xlg-min: 1600px;

@width-narrow: 500px;
@width-medium: 680px;
@witdh-wide: 830px;

@desktopApp: 1200px;
@mediumScreen: 1600px;

@containerWidthSm: 575px;
@containerWidthMd: 780px;
@containerWidthLg: 1035px;
@containerWidthXlg: 33%;

.sca-payment-wrapper {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .decorative-col {
        position: absolute;
        top: 0;
        bottom: 0;
        left: @containerWidthLg;
        width: calc(100% - @containerWidthLg);
        height: 100%;
        min-height: 920px;
        display: flex;
        flex-direction: column;
        @media only screen and (max-width: @mediumScreen) {
            left: @containerWidthMd;
            width: calc(100% - @containerWidthMd);
        }
        @media only screen and (max-width: @desktopApp) {
            left: @containerWidthSm;
            width: calc(100% - @containerWidthSm);
        }
        @media only screen and (min-width: 1920px) {
            max-width: @containerWidthXlg;
            right: 0;
            left: auto;
        }
    }

    .decorative-col--top-section {
        background-color: @white;
        flex: 1 1 650px;
        &::after {
            content: '';
            position: absolute;
            background-image: url('assets/dots.svg');
            background-repeat: no-repeat;
            width: 140px;
            height: 140px;
            display: inline-block;
            left: -220px;
            top: 280px;
            z-index: 0;

            @media only screen and (max-width: @mediumScreen) {
                background-size: contain;
                height: 130px;
                left: -145px;
                top: 265px;
            }
            @media only screen and (max-width: @desktopApp) {
                content: none;
            }
        }

        &:after {
            left: -150px;
        }

        &:before {
            content: '';
            height: 700px;
            width: 1050px;
            display: inline-block;
            position: absolute;
            top: -50px;
            left: -140px;
            background-image: url('assets/bg.png');
            background-repeat: no-repeat;
            z-index: 2;
        }
    }

    .perMonth {
        font-size: @font-size-UI16;
    }
}

.sca-payment-content {
    position: relative;
    z-index: 1;
    margin: 40px 0 0 50px;
    width: @width-narrow;

    @media only screen and (min-width: @screen-lg-max) {
        width: @width-medium;
    }

    @media only screen and (min-width: @screen-xlg-min) {
        margin: 70px 0 0 70px;
        width: @witdh-wide;
    }
}

.sca-payment-title {
    font-size: @font-size-H24;
    font-weight: bold;
    color: @black;
    margin: 0 0 0.5em;

    &.sca-payment-title__foot {
        font-size: @font-size-H24;
    }

    @media only screen and (min-width: @screen-lg-min) {
        font-size: @font-size-H24;
    }
}

.sca-payment-info {
    font-size: @font-size-UI18;
    margin: 0;
    padding: 0;

    @media only screen and (max-width: @screen-lg-min) {
        font-size: 15px;
    }
}

.sca-payment-form {
    width: 370px;
    margin: 32px 0;
}

.sca-payment-receipt {
    background-color: @white;
    border-radius: 5px;
    box-shadow: 0 7px 15px #ddd;
}

.sca-payment-receipt__row {
    display: flex;
    justify-content: space-between;
    padding: 1.2em 1.7em;
}

.sca-payment-receipt__item {
    display: flex;
    flex-direction: column;
}

.sca-payment-receipt__item-details {
    list-style-type: none;
    margin: 0.5em 0 0 0.25em;
    padding: 0;

    li {
        line-height: 1.6em;
        position: relative;
        padding: 0 0 0 20px;

        &:before {
            font-family: 'Ionicons';
            font-size: 0.8em;
            content: '\f121';
            color: #63bf4c;
            position: absolute;
            left: 0px;
        }
    }
}

.sca-payment-receipt__item-main {
    font-weight: bold;
    font-size: @font-size-H20;
}

.sca-payment-receipt__price {
    font-size: @font-size-H20;

    .sca-payment-receipt__row--total & {
        font-weight: bold;
    }
}

.sca-payment-receipt__row--total {
    border-top: 1px solid @gray-100;
}

.sca-payment-cta {
    background-color: @green-500;
    font-size: @font-size-UI18;
    color: @white;
    width: 100%;
    border-radius: 5px;
    margin-top: 24px;
    line-height: 46px;
    height: 46px;
    border: none;
    outline: none;
    cursor: pointer;
}

.sca-payment-contact {
    width: @width-narrow;
    font-size: 15px;
    border-top: 1px solid #ccc;
    padding-top: 16px;
    margin-top: 16px;

    @media only screen and (min-width: @screen-lg-max) {
        padding-top: 32px;
        margin-top: 32px;
        width: @width-medium;
    }

    @media only screen and (min-width: @screen-xlg-min) {
        width: @witdh-wide;
    }

    a {
        color: #000;
        text-decoration: underline;
    }
}

.sca-payment-message {
    width: 475px;
    padding: 1em 2em;
    border-radius: 5px;
    margin: 2em 0;

    &.is-error {
        background-color: fade(@red-500, 30);
        color: @red-500;
    }

    &.is-success {
        background-color: fade(@green-500, 30);
        color: @green-500;
    }
}
