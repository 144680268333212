@import '../../../shared/components/design';

@green: #008744;
@blue: #0abbff;
@red: #95bf47;
@yellow: #31419f;

// scaling... any units
@width: 220px;
.shopifyIntegrationBody {
    background-color: #eff1f5;
    #whiteBox {
        background: @white;
        background-size: cover;
        position: absolute;
        width: 100%;
        bottom: 60px;
        top: 0;
        left: 0;
        overflow: hidden;
        font-family: euclidcircularb, sans-serif;
        .text {
            position: absolute;
            top: 50%;
            left: 60px;
            margin-top: -5px;
            .enterStore {
                width: 391px;
                display: block;
                position: relative;
                span {
                    font-size: 19px;
                    font-weight: normal;
                    color: #a0a7b7;
                    padding-top: 4px;
                }
                .beforeShopText {
                    position: absolute;
                    width: 56px;
                    left: 0;
                    top: 0;
                }
                .afterShopText {
                    position: absolute;
                    width: 141px;
                    top: 0;
                    right: 0;
                }
                .clear {
                    clear: both;
                }
            }
            &.enterShopUrl {
                margin-top: -90px;
            }
            span {
                font-size: 34px;
                color: #2b3544;
                font-weight: 600;
            }
            span.blue {
                font-size: 20px;
                color: #0abcff;
                display: block;
                font-weight: 600;
            }
            label,
            input,
            button {
                display: block;
            }
            label {
                font-size: 15px;
                margin-top: 20px;
            }
            input {
                border: 0;
                outline: none;
                color: #000;
                font-size: 19px;
                width: 391px;
                padding: 3px 145px 10px 56px;
                border-bottom: 3px solid #e0e6ea;
                float: left;
                &:focus {
                    border-bottom-color: #00bef8;
                }
                &::placeholder {
                    color: #b1b3b7;
                    opacity: 1;
                }
                &::-ms-input-placeholder {
                    color: #bec3cd;
                    opacity: 1;
                }
            }
            button {
                outline: none;
                cursor: pointer;
                background-color: #0087f1;
                border: 0;
                padding: 10px 40px;
                color: @white;
                border-radius: 3px;
                margin-top: 30px;
            }
            #enterShopForm {
                label {
                    span {
                        display: none;
                    }
                }
                &.error {
                    label {
                        span#error {
                            display: inline-block;
                            margin-left: 5px;
                            font-size: 15px;
                            font-weight: 400;
                            color: #ff3044;
                        }
                    }
                    input {
                        border-bottom-color: #ff3044;
                    }
                }
                &.errorWrong {
                    label {
                        span#errorWrong {
                            display: inline-block;
                            margin-left: 5px;
                            font-size: 15px;
                            font-weight: 400;
                            color: #ff3044;
                        }
                    }
                    input {
                        border-bottom-color: #ff3044;
                    }
                }
            }
        }
    }
    #bottomBar {
        display: none;
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        line-height: 60px;
        font-weight: 600;
        font-size: 16px;
        z-index: 9;
        background-color: #eff1f5;
        font-family: euclidcircularb, sans-serif;
        &.visible {
            display: block;
        }
    }
    #rightSide {
        background: transparent url('rightSide.svg') no-repeat right;
        background-size: contain;
        position: absolute;
        right: 0px;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
    }
    #showbox {
        position: absolute;
        top: 50%;
        bottom: 0;
        right: 85px;
        margin-top: -110px;
        overflow: hidden;
    }

    .shopify-loader {
        position: relative;
        margin: 0 auto;
        width: @width;
        &:after {
            background: url('shopify.png') no-repeat center center;
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }
    }

    .circular {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .pathBackground {
        stroke: #dde3e7;
    }
    .path {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
        animation:
            dash 1.5s ease-in-out infinite,
            color 6s ease-in-out infinite;
        stroke-linecap: round;
    }
    .success {
        .pathBackground {
            stroke: #58b744;
        }
        .path {
            display: none;
        }
        &:after {
            content: '';
            position: absolute;
            right: 28px;
            top: 28px;
            width: 41px;
            height: 41px;
            background: url('check.png') no-repeat;
        }
    }
    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -35px;
        }
        100% {
            stroke-dasharray: 89, 200;
            stroke-dashoffset: -124px;
        }
    }

    @keyframes color {
        100%,
        0% {
            stroke: @red;
        }
        40% {
            stroke: @blue;
        }
        66% {
            stroke: @green;
        }
        80%,
        90% {
            stroke: @yellow;
        }
    }
}
