@import '../../../../media-to-refactor/bootstrap/mixins/buttons';
@import '../../../../shared/components/design';

.test-email {
    .btn.btn-link {
        text-decoration: underline;
        color: @gray-500;
    }
    .semicolon-badge {
        display: inline-block;
        text-align: center;
        width: 24px;
        height: 24px;
        background-color: @gray-100;
        color: @black;
        border-radius: 50%;
    }
    .loading {
        padding-bottom: 24px;
        padding-top: 32px;
        &:before {
            background-image: url('../../../../public/media/img/panel/loading-58.png');
            width: 58px;
            height: 58px;
        }
    }
    .result-icon {
        display: block;
        margin: 20px auto 20px auto;
        width: 148px;
    }
    .campaign-confirm {
        display: flex;
        flex-direction: column;

        font-size: @font-size-UI16;
        line-height: 24px;
        min-height: 110px;
        border-radius: 4px;
        box-shadow: 0 12px 32px 0 rgba(136, 148, 171, 0.24);
        align-items: stretch;
        justify-content: flex-start;
        position: relative;
        background-color: @white;
        padding: 20px 24px;
        &:before {
            display: block;
            content: '';
            background-color: @gray-100;
            border-radius: 4px;
            position: absolute;
            left: -10px;
            right: -10px;
            top: 30px;
            height: calc(100% - 20px);
            z-index: -1;
        }
        & > div {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
        }
        & > div > div {
            flex: 1;
            word-break: break-word;
            &:first-of-type {
                font-weight: bold;
                flex: 0 0 80px;
                flex-basis: 90px;
                margin-left: 10px;
            }
        }
        .audience {
            display: flex;
            align-items: center;
        }
        &--insufficient-quota {
            .audience:before {
                content: '!';
                color: @red-500;
                position: absolute;
                left: -1px;
                border: 2px solid @red-500;
                border-radius: 50%;
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 11px;
                font-weight: bold;
                background-color: @white;
                box-shadow: 0 0 0px 3px @white;
            }
        }
        &--scheduled {
            .audience:before {
                content: '';
                width: 29px;
                height: 26px;
                background: url(../assets/warning.svg) no-repeat;
                position: absolute;
                left: -10px;
            }
        }
        &--insufficient-quota,
        &--scheduled {
            &:after {
                content: '';
                width: 47px;
                height: 112px;
                background: url(../assets/confirm-mail-line.svg);
                position: absolute;
                bottom: -76px;
                left: -50px;
            }
        }
    }
    .quota-limit-info {
        margin: 48px auto 24px;
        max-width: 390px;
        font-size: @font-size-UI16;
        line-height: 18px;
    }
}
