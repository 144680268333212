@import '../../../../shared/components/design';
.onIE(@rules) {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @rules();
    }
}
.filter-wrapper {
    display: flex;
    align-items: stretch;
    background-color: @white;
    border-radius: 12px;
    border: 1px solid @gray-150;
    flex-shrink: 0;
    .chevron {
        background-color: white;
        flex: 0 0 20px;
        margin-left: -18px;
        margin-right: -18px;
        .onIE({display: none;});
        svg {
            height: 100%;
        }
    }
}
.filters {
    padding: 13px 0;
    display: flex;
    flex: 1;
    align-items: center;
    flex-wrap: wrap;
    font-size: @font-size-UI16;
    .onIE({padding-left: 13px;});
    @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    &.filter-type {
        display: flex;
        align-items: center;
        flex: 0 0 255px;
        padding-left: 13px;
        padding-right: 0;
        .onIE({border-right: 1px solid @athens-gray; padding-right: 18px;});
        .dropdown {
            width: 100%;
            display: flex;
            &__trigger {
                flex: 1;
            }
        }
        span {
            min-height: 30px;
            display: flex;
            align-items: center;
            white-space: nowrap;
            margin: 4px 0 4px auto;
            svg.arrow-down {
                fill: @black;
                margin-left: 20px;
                margin-right: 0;
            }
        }
        .icon svg {
            fill: @gray-200;
            margin-left: 8px;
            margin-right: 16px;
            flex: 0 0 24px;
        }
        @media screen and (max-width: 1200px) {
            flex: 0 1 210px;
            flex-direction: row;
            align-items: center;
            .filter-type-label {
                white-space: normal;
                flex: 0 1 110px;
            }
            span {
                svg.arrow-down {
                    margin-left: 10px;
                }
            }
        }
    }
    #add-filter-dropdown {
        &:before {
            top: 0;
            font-size: inherit;
        }
        @media screen and (max-width: 1200px) {
            &:before {
                left: 2px;
                margin-right: 20px;
            }
        }
    }
    .add-filter {
        &__dropdown {
            position: relative;
            display: inline-block;
        }
        &__dropdown-container {
            background: @white;
            background-position: -19px -15px;
            box-shadow: 0px 8px 24px 0 @oxford-blue32;
            width: 242px;
            border-radius: 8px;
            padding: 8px 0;
            position: absolute;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s ease-in-out;
            list-style: none;
            z-index: 3;
            transform: translateY(3px);

            &.visible {
                opacity: 1;
                visibility: visible;
                transform: translateY(11px);
            }
        }
        &__dropdown-item {
            font-size: @font-size-UI14;
            &:focus {
                outline: none;
            }
            button {
                background-color: transparent;
                border: none;
                width: 100%;

                padding: 11px;
                display: flex;
                align-items: center;
                color: #222b38;
                &:focus {
                    outline: none;
                }
                &.selected {
                    background-color: @gray-50;
                }
                &:hover,
                &:active,
                &:focus {
                    background-color: #edf0f4;
                }
            }
        }
    }
    .filter-item {
        color: @gray-800;
        display: flex;
        align-items: center;
        min-height: 30px;
        border-right: 1px solid #e9ecef;
        margin: 4px 9px;
        flex-wrap: nowrap;
        cursor: pointer;
        @media screen and (max-width: 1200px) {
            border-right: none;
            .filter-text {
                white-space: normal;
            }
        }
        .filter {
            display: flex;
            align-items: flex-start;
            .icon {
                display: flex;
                align-items: center;
                svg {
                    fill: @gray-200;
                    margin-right: 12px;
                }
            }
            &-type {
                font-style: italic;
                margin-left: 2px;
            }
            &:hover .icon svg {
                fill: @primary-blue-500;
            }
        }
        .dropdown--active {
            .icon svg {
                fill: @primary-blue-500;
            }
        }
        .condition-value {
            font-weight: bold;
            &:before {
                content: ': ';
                font-weight: normal;
                font-style: italic;
            }
        }
        .dropdown__trigger {
            padding-right: 10px;
        }
        .icon-close {
            cursor: pointer;
            background-color: transparent;
            border: none;
            margin-right: 10px;
            padding: 0 10px;
            display: inline-flex;
            align-items: center;
            svg {
                width: 20px;
                height: 20px;
                fill: @gray-200;
            }
            &:hover svg {
                fill: @gray-500;
            }
        }
    }
    .dropdown__list-edit-filter {
        width: 256px;
    }
}

.segments-save {
    display: flex;
    align-items: center;
    padding: 12px 20px 12px 0;
    flex: 0 2 190px;
    justify-content: flex-end;
    .dropdown__list-save-segment {
        li {
            padding: 0 8px;
        }
        label {
            font-size: @font-size-UI14;
            font-weight: normal;
            padding-top: 4px;
            display: flex;
            margin-bottom: 4px;
        }
        .save-segment {
            margin-top: 20px;
            padding-top: 4px;
            box-shadow: 0px -1px 0 0 #e9ecef;
            text-align: center;
            display: block;
            button {
                padding: 0 6px;
            }
        }
        .error {
            &-label {
                color: #ff2439;
                margin-left: auto;
            }
        }
    }
}
