@import '../../../../shared/components/design';
.horizontal(@start-color: #555; @end-color: #333; @start-percent: 90%; @end-percent: 100%) {
    background-image: linear-gradient(
        to right,
        @start-color @start-percent,
        @end-color @end-percent
    );
    background-color: transparent !important;
}

.contacts {
    .data {
        position: relative;
    }
    .table {
        .name,
        .name a {
            display: flex;
            align-items: center;
            .avatar {
                cursor: pointer;
            }
            .contact-channels {
                margin-left: auto;
                padding-left: 12px;
            }
        }
        .name--readonly .avatar {
            cursor: default;
        }
        td,
        th {
            min-width: 100px;
            white-space: nowrap;
        }
        tr {
            border-bottom: 1px solid @gray-100;
            &:last-of-type:not(:first-of-type) {
                border-bottom: none;
            }
        }
        th {
            &.sortable {
                position: relative;
                &:hover {
                    background-color: rgb(245, 247, 249);
                }
            }
        }
        .fixed-cell {
            position: sticky;
            left: 0;
            display: flex;
            align-items: center;
            padding-left: 22px;
            min-width: 62px;
            width: 1%;
            z-index: 1;
            &.shake label {
                animation: shakeTextarea 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            }
        }
        th.fixed-cell,
        td.fixed-cell {
            background: transparent;
            .horizontal(@start-color: @white; @end-color: rgba(255, 255, 255, 0) ;);
        }
        td.wide-cell {
            min-width: 160px;
        }
        .column-name {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin: 0;
            padding: 0 16px;
            border: none;
            background-color: transparent;
            font-weight: 400;
            color: @gray-500;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            box-shadow: none;
            outline: none;
            svg {
                fill: @gray-500;
            }
            .column-sort {
                display: flex;
                align-items: center;
                opacity: 0;
                transition: opacity 0.2s;
                margin-left: 4px;
            }
            &.asc,
            &.desc {
                .column-sort {
                    opacity: 1;
                }
            }
            &.asc {
                .column-sort {
                    transform: rotate(180deg);
                }
            }
            &:not(.not-sortable) {
                &:hover {
                    .column-sort {
                        opacity: 1;
                    }
                }
            }
            &.not-sortable {
                pointer-events: none;
            }
        }
    }
    .rating {
        svg {
            width: 22px;
            height: 22px;
        }
        &-good {
            fill: #8fd47f;
        }
        &-bad {
            fill: #b8c0ce;
        }
    }
}

.table-hoverable {
    tbody tr:hover {
        background-color: rgb(245, 247, 249);
        .fixed-cell {
            .horizontal(
                @start-color: rgb(245, 247, 249) ; @end-color: rgba(245, 247, 249, 0) ;
            );
        }
        .custom-checkbox {
            span {
                border: 2px solid @primary-blue-500;
                opacity: 1;
            }
        }
    }
}

@keyframes shakeTextarea {
    10%,
    90% {
        margin-left: -1px;
    }

    20%,
    80% {
        margin-left: 1px;
    }

    30%,
    50%,
    70% {
        margin-left: -2px;
    }

    40%,
    60% {
        margin-left: 2px;
    }
}
