@import '../../../../../../../shared/components/design';

// Colors names
@dodgerBlue: #09c4ff;
// Roles
@testButtonIconBackground: @dodgerBlue;
.channelsSelector {
    height: 36px;
    display: flex;
    align-items: center;
    &__switchesTutorialWrapper {
        display: flex;
        height: auto;
    }
    &.introjs-fixParent {
        position: relative;
        top: 61px;
        width: 100%;
    }
    &__option {
        &Column {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            &.introjs-showElement {
                .channelsSelector__optionCell {
                    z-index: 10;
                    position: relative;
                }
            }
        }
        &Cell {
            padding: 0 13px;
            &--center {
                justify-content: center;
            }
        }
    }
    &__button {
        display: flex;
        align-items: center;
        margin: 0;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        border: 1px solid transparent;
        white-space: nowrap;
        line-height: 1.42857143;
        border-radius: 4px;
        user-select: none;
        font-size: @font-size-UI14;
        color: @white;
        background-color: #68c462;
        transition: background-color 0.1s ease-in-out;
        padding: 6px 45px 8px;
        outline: none;
        min-width: 123px;
        justify-content: center;
        &:not(.channelsSelector__buttonWhiteSaving):not(.channelsSelector__buttonWhiteSaved):hover {
            background-color: @green-500;
        }
        &White {
            background-color: @white;
            border: 2px solid #f1f2f7;
            color: #8892a3;
            padding-top: 6px;
            padding-bottom: 6px;
            &Saving {
                span {
                    position: relative;
                    &:after {
                        position: absolute;
                        left: 47px;
                        overflow: hidden;
                        display: inline-block;
                        vertical-align: bottom;
                        -webkit-animation: ellipsis steps(4, end) 900ms infinite;
                        animation: ellipsis steps(4, end) 900ms infinite;
                        content: '\2026';
                        /* ascii code for the ellipsis character */
                        width: 0px;
                    }
                    @keyframes ellipsis {
                        to {
                            width: 16px;
                        }
                    }
                    @-webkit-keyframes ellipsis {
                        to {
                            width: 16px;
                        }
                    }
                }
            }
            &Saved {
                padding: 6px 33px 6px 47px;
                span {
                    position: relative;
                    display: inline-block;
                    &:after {
                        font-family: 'Ionicons';
                        content: '\f122';
                        color: @green-500;
                        display: block;
                        width: 20px;
                        height: 20px;
                        position: absolute;
                        left: -20px;
                        top: 0;
                        border-radius: 50%;
                        text-align: center;
                    }
                }
            }
            &OpenTestingWindow {
                height: 40px;
                padding: 6px 25px 10px;
                color: #494d51;
                border-color: @testButtonIconBackground;
                background-color: @testButtonIconBackground;
                color: @white;
                &:hover {
                    background-color: #04adf1;
                    border-color: #04adf1;
                }
                &--disabled {
                    @disabledColor: #d4d8e1;
                    cursor: default;
                    opacity: 0.8;
                    border-color: @disabledColor;
                    color: #767a82;
                    &:before {
                        background-color: @disabledColor;
                    }
                }
            }
        }
    }
}

// Animations
.spin(@duration) {
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    animation-name: spin;
    animation-duration: @duration;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
