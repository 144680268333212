@import '../../../../../shared/components/design';
.upload-container {
    height: 176px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    outline: none;
    position: relative;
    border-radius: 6px;
    border: 2px dashed @gray-200;
    margin-right: 40px;
    p {
        margin: 0;
        line-height: 19px;
        color: @black;
        font-size: @font-size-UI16;
    }
    .loading {
        padding: 0;
        margin-bottom: 14px;
    }
    &.start {
        &:hover {
            cursor: pointer;
        }
    }
    .upload-info {
        text-align: center;
        svg {
            margin-bottom: 10px;
        }
        .link {
            color: @primary-blue-500;
        }
        .extensions {
            color: @gray-500;
        }
        .btn-link {
            outline: none;
            padding: 0;
            &:hover {
                cursor: pointer;
            }
        }
    }
}
